.passwordResetPage {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
  
.resetButton {
    width: 30%;
    height: 25px;
}

.buffer{
    margin: 10px;
    margin-bottom: 20px;
}

.buttons {
    padding-top:15px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.formBorderBufferReset {
    margin: 10px;
    margin-bottom: 20px;
}
