.loginPage {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formBorderBuffer {
  margin: 20px;
  margin-bottom: 40px;
}

.switchViewButton {
  width: 50%;
  height: 24px;
}

.loginButton {
  width: 80%;
  height: 30px;
}

.loginSecondayButton {
  width: 40%;
  height: 24px;
}

.secondaryButtons {
  padding-top:15px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.teamLogoImage {
  max-width: 50%;
  height: 25%;
}