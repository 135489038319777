.signInOutPage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.welcomeText {
  font-size: 26px;
  color: #3D3988;
}

.lastSignText {
  font-size: 18px;
  color: #3D3988;
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.textUnderline {
  border-bottom: 2px;
  border-left: 0px;
  border-top: 0px;
  border-right: 0px;
  border-color: #3D3988;
  border-style: solid;
  height: 50px;
  justify-content: center;
  align-items: end;
  display: flex;
  font-weight: 500;
}

.dataContainer {
  display: flex;
  flex-direction: column;
  padding: 5px;
  height: 80px;
  width: 275px;
  max-width: 35%;
}

.hourText {
  font-size: 22px;
  font-weight: 700;
}

.signInButton {
  width: 80%;
  max-width: 300px;
  background-color: green;
}

.alignSessionButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.sessionText {
  padding-bottom: 20px;
  font-size: 24px;
  color: #3D3988;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 90%;
  max-width: 300px;
}

.sessionHourText {
  font-weight: 700;
}

.labelText {
  color: #3D3988;
  font-weight: 500;
  font-size: 24px;
}

.switchBox {
  border: 2px;
  border-color: #7b2185;
  box-shadow: 1px 5px #7b2185;
  border-style: solid;
  border-radius: 30%;
  padding: 20px;
  max-width: 80%;
  width: 275px;
}

.modalScreen {
  display: flex;
  justify-content: center;
  align-items: center;
}