.adminPage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.headerText {
  font-size: 32px;
  font-weight: 700;
  color: #3D3988;
}

.userListBox {
  border: 2px;
  border-style: solid;
  border-color: #3D3988;
  width: 100%;
  max-width: 400px;
  height: 100%;
  max-height: 400px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.userListBox::-webkit-scrollbar {
  display: none;
}


.userRoleList {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

.userRole {
  padding: 5px;
  font-size: 18px;
  font-weight: 500;
}

.userListAlign {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
}

.userName {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  font-size: 18px;
  font-weight: 500;
}

.userListData {
  width: 95%;
  max-width: 400px;
  font-size: 26px;
  font-weight: 600;
  color: #3D3988;
}

.editSignButton {
  width: 100%;
  max-width: 100px;
  height: 30px;
  color: #3D3988;
}

.adminPageSignListAlign {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: scroll;
  padding-top: 20px;
  max-width: 600px;
}

.adminPageSignListFullSize {
  width: 100%;
  height: 100%;
  max-width: 600px;
}

.adminPageSignListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 5px;
}

.modalScreen {
  background-color: azure;
}

.modalAlign {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 90%;
  width: 90%;
  max-width: 600px;
}

.adminPageSignListAlign::-webkit-scrollbar {
  display: none;
  }

  .displayName {
    font-size: 24px;
    display: flex;
    align-self: center;
    justify-content: center;
    padding: 10px;
  }

.roboticsText {
  font-size: 20px;
  font-weight: 500;
  color: #3D3988;
}

.volunteerText {
  font-size: 20px;
  font-weight: 500;
  color: #61c965;
}