.SideMenuBar {
  position: absolute;
  display: flex;
  align-self: center;
  top: 0;
  width: 100%;
  height: 30px;
  background-color: #3D3988;
}

.SideMenuBarButton {
  width: 30px;
  height: 30px;
  align-self: center;
  top: 0;
  padding-left: 10px;
  display: flex;
  justify-content: center;
}

.SideMenuDropdown {
  position: absolute;
  z-index: 1;
  top: 0;
}