.globalTeamDataPage {
    font-size: 22px;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
}

.userTimeList {
    font-size: 22px;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.userForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 3px;
    border-style: solid;
    border-radius: 10%;
    border-color: #7b2185;
    box-shadow: 1px 5px #7b2185;
    padding: 10px;
    max-height: 50vh;
}

.userTimeList::-webkit-scrollbar {
    display: none;
}

.userBox {
    align-items: center;
    padding: 10px;
}

.userBox {
    align-items: center;
    padding: 10px;
}

.buffer {
    margin: 10px;
    margin-bottom: 20px;
}

.userDisplay {
    font-size: 22px;
    justify-content: center;
    border-style: solid;
    border-radius: 10%;
    border-color: #7b2185;
    box-shadow: 1px 5px #7b2185;
    padding: 10px;
}

.userDisplay1st {
    color: black;
    font-size: 22px;
    display: flex;
    justify-content: center;
    border-style: solid;
    border-radius: 10%;
    border-color: #FFD700;
    box-shadow: 1px 5px #FFD700;
    padding: 10px;
}

.userDisplay2nd {
    color: black;
    font-size: 22px;
    display: flex;
    justify-content: center;
    border-style: solid;
    border-radius: 10%;
    border-color: #C0C0C0;
    box-shadow: 1px 5px #C0C0C0;
    padding: 10px;
    margin-bottom: 20px;
}

.userDisplay3rd {
    color: black;
    font-size: 22px;
    display: flex;
    justify-content: center;
    border-style: solid;
    border-radius: 10%;
    border-color: #CD7F32;
    box-shadow: 1px 5px #CD7F32;
    padding: 10px;
}

.switchForm {
    border: 3px;
    width: 60px;
    height: 80px;
    border-style: solid;
    border-radius: 10%;
    border-color: #7b2185;
    box-shadow: 1px 5px #7b2185;
    padding: 10px;
    font-size: 15px;
    font-weight: 500;
}

.switchBuffer{
    position:absolute;
    top:0;
    right:0;
    padding-top: 40px;
    padding-right: 10px
}

.user2ndBuffer{
    font-size: 25px;
    color: #C0C0C0;
    justify-content: left;
    padding: 10px;
    margin: 10px;
    max-width: 140px;
}

.user3rdBuffer{
    font-size: 20px;
    color: #CD7F32;
    justify-content: right;
    padding: 10px;
    margin: 10px;
    max-width: 140px;
}

.user1stBuffer{
    margin-top: 5%;
    font-size: 35px;
    color: #FFD700;
    max-width: 140px;
}

.positionBoxes{
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 400px;
}

.rowAlign{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.totalTeamHours{
    font-size: 22px;
}

.currentHoursShown{
    font-size: 22px;
    font-weight: 700;
}
