.fullEventViewPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.teamScoutAtAGlanceDataGridBox {
  width: 70vw;
  height: 550px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.dataViewButtonExport {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 90%;
  max-width:  300px;
  display: flex;
  justify-content: center;

}

.teamScoutAtAGlanceDataGridBox::-webkit-scrollbar {
  display: none;
}

.modalScreenAlignCharts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.teamScoutCheckboxAlign6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.teamScoutQuickMatchSelect3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: left;
  width: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

@media only screen and (max-width: 600px) {
  
  .teamScoutAtAGlanceDataGridBox {
    width: 90%;
    height: 300px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

}

