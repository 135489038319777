.modalBox {
  width: 300px;
  height: 350px;
}

.modalOutline {
  border: 2px;
  border-color: #7b2185;
  box-shadow: 1px 5px #7b2185;
  border-style: solid;
  border-radius: 30%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  background-color: #8e8bc7;
}

.currentTime {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.headerText {
  border: 2px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-color: #7b2185;
  border-style: solid;
  font-size: 26px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.subText {
  font-size: 22px;
  text-transform: capitalize;
  padding: 5px;
}

.startEndText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.smallText {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  width: 100%;
  font-size: 18px;
}
.underline {
  border: 2px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-color: #7b2185;
  border-style: solid;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeAlign {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}