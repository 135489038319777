.pitScoutingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.pitScoutingTeamNumber {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  font-size: 24px;
  margin: 10px;
  color: #3D3988;
}

.pitScoutingAlignHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  min-height: 100px;
  width: 100%;
  font-size: 40px;
  border-bottom: 4px solid #3D3988;
  max-height: 100px;
  padding-top: 40px;
}

.scoutingPageFormFields {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 70%;
  width: 80%;
  max-width: 400px;
  padding-top: 20px;
}

.scoutingPageFields::-webkit-scrollbar {
  display: none;
}

.pitScoutingTeamNumberIncrease {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  font-size: 60px;
  margin-bottom: 10px;
  color: #3D3988;
}

.scoutingPageFields {
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  align-items: center;
  }

  .scoutingPageField {
    padding: 7px;
    width: 95%;
  }

  .scoutingPageTextHeader1 {
    font-size: 28px;
    color: #3D3988;
    border-bottom: 2px solid #3D3988;
    margin-bottom: 10px;
  }
  .scoutingPageTextHeader2 {
    font-size: 22px;
    color: #3D3988;
    border-bottom: 1px solid #3D3988;
    margin-bottom: 10px;
  }

  .scoutingPitSubmitButton {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    width: 80%;
    font-size: 30px;
    color: #3D3988;
  }

  .buttonSpacing {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }

  .modalScrollAllowment {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .modalScrollAllowment::-webkit-scrollbar {
    display: none;
  }