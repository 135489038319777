.userRegisterPage {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.formBorderBuffer {
  margin: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bottomButtons {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 15px;
}