.teamScoutPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}

.teamScoutMatchChartDisplay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    min-width: 100vw;
    margin-bottom: 10px;
}

.MatchScoutingPageFormFields2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 90%;
    width: 98vw;
    padding-top: 20px;

}

.teamScoutPitScoutButton {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 150px;
    height: 30px;
    font-size: 30px;
    font-weight: bold;
    border-radius: 10px;
    border: 2px solid #000000;
    background-color: #FFFFFF;
}

.modalScreenAlign {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 80%;
}

.modalScreenButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly ;
  align-items: center;
  width: 90%;
  max-width: 300px;
}

.modalTextAlign {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 160px;
    text-align: center;
    font-size: 22px;
}

.warningText {
    font-size: 40px;
    font-weight: bold;
    color: #FF0000;
}

.modalSignButton {
  width: 80px;
}

.teamScoutTitle {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #3D3988;
}

.teamScoutSubTitle {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #3D3988;
    width: 100%;
  }

  .teamScoutTitleWidth {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    min-height: 100px;
    width: 100%;
    font-size: 40px;
    border-bottom: 4px solid #3D3988;
    max-height: 100px;
  }

  .modalScreenPitData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;
    width: 95%;
    max-width: 400px;
    padding-bottom: 20px;
  }

  .scoutingPageAllowScroll {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 100%;
  }

  
.scoutingPageAllowScroll::-webkit-scrollbar {
  display: none;
}

.teamScoutQuickMatchSelect1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin-bottom: 10px;
  max-width: 500px;
}

.teamScoutCheckboxAlign5 {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;

}

.teamScoutButtonAlignment {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 10px;
}

  .scoutingPageTextHeader1 {
    font-size: 28px;
    color: #3D3988;
    border-bottom: 2px solid #3D3988;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .scoutingPageTextHeader2 {
    font-size: 22px;
    color: #3D3988;
    border-bottom: 1px solid #3D3988;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .dataDisplayAlign {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 10px;
  }

  .textChange1 {
    font-size: 22px;
    color: black;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .textChange2 {
    font-size: 18px;
    color: black;
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    border-bottom: 1px dashed black;
    overflow-wrap: break-word;
    text-align: center;
    word-break:break-all;
  }

  .teamScoutMatchText {
    font-size: 22px;
    color: black;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .teamScoutAtAGlanceBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 10px;
  }

  .teamScoutAtAGlance {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 10px;
  }

  .teamScoutAtAGlanceText {
    font-size: 22px;
    color: black;
    width: 100px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }

  .teamScoutAtAGlanceText1 {
    font-size: 18px;
    color: black;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    border-bottom: 1px solid black;
    font-weight: 600;
  }

  .teamScoutAtAGlanceText2 {
    font-size: 18px;
    color: black;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .teamScoutAtAGlanceText5 {
    font-size: 14px;
    color: black;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    border-bottom: 1px solid black;
    font-weight: 600;
  }
  .teamScoutAtAGlanceText6 {
    font-size: 14px;
    color: black;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 600;
  }


.teamScoutAtAGlanceAlign {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 10px;
}

.teamScoutFullPageTakeup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

}

.scrollFixes {
  display: flex;
  width: 100%;
  height: 100%;
}

.teamScoutFullPageTakeup::-webkit-scrollbar {
  display: none;
}

.teamScoutAtAGlanceTitle {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #3D3988;
  border-bottom: 2px solid #3D3988;
  width: 100%;
}

.teamScoutAtAGlanceAlign2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.teamScoutAtAGlanceTitle1 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #3D3988;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #3D3988;
}

.teamScoutAtAGlanceFullWidth {
  width: 100%;
  max-width: 400px;
}

.teamScoutQuickCheckboxes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 10px;
  max-width: 400px;
}

.teamScoutQuickMatchSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  margin-bottom: 10px;
}

.teamScoutCheckboxAlign {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 10px;
}

.teamScoutButtonSpacing {
  margin: 10px;
  color: #FFFFFF;
}

.MatchScoutingPageFormFields {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 70%;
  width: 100%;
  max-width: 400px;
  padding-top: 20px;
}
.teamScoutCheckboxAlign2 {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 10px;
  max-width: 400px;
}

.teamScoutMatchListDisplay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 10px;
  max-width: 400px;
}

.teamScoutMatchListAlign {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  max-width: 400px;
}

.teamScoutMatchListAdjust {
  margin-bottom: 10px;
  max-width: 400px;
}

.modalScrollBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100vw;
  margin-bottom: 10px;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modalScrollBox::-webkit-scrollbar {
  display: none;
}

.MatchScoutingPageFormFields1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 90%;
  width: 100vw;
  padding-top: 20px;
  max-width: 400px;
}

.scoutingPageFields1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 400px;
  padding-top: 20px;
}

.teamScoutAtAGlanceAlign3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 10px;
}

.teamScoutAtAGlanceAlign4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 10px;
}

.teamScoutAtAGlanceText3 {
  font-size: 18px;
  color: black;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid black;
  font-weight: 600;
  margin-bottom: 10px;
}

.matchScoutingShootingBoxes1 {
  transform: rotate(0deg);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.matchScoutFieldImg1 {
  width: 95%;
  max-width: 600px;
  margin-bottom: 10px;
}

.matchScoutGridItemNotes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;
  max-width: 400px;
  padding-top: 5px;
  text-align: center;
}

.teamScoutGridAlignItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 400px;
  padding-top: 20px;
  text-align: center;
}

.matchScoutGridItemNotesTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;
  max-width: 400px;
  text-align: center;
  border-bottom: 1px solid black;
}

.teamScoutCheckboxAlign4{ 
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 10px;
  max-width: 400px;
}