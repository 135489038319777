body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.globalBackground {
  background: linear-gradient(to right,#f0f1f0,#e0f5e5);
  justify-content: center;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center; 
  animation: slide 10s linear infinite;
  flex-direction: row;
}

.fullScreen {
  height: 100vh;
  width: 100vw;
}

.globalForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 3px;
  border-style: solid;
  border-radius: 10%;
  border-color: #7b2185;
  box-shadow: 1px 5px #7b2185;
  padding: 10px;
}

.errorText {
  color: red;
}

.spacer {
  height: 5px;
}

.titleText {
  font-size: 30px;
  color: #3D3988;
}