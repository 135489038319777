.matchScoutingPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.matchScoutingButton {
  width: 90%;
  max-width: 300px;
}

.matchScoutButtonList {
  display: flex;
  height: 70%;
  justify-content: space-evenly;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.matchScoutGridList {
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.matchScoutGridItem {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  max-width: 50px;
  max-height: 50px;
  width: 15vw;
  height: 15vw;
  border: 1px solid black;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  box-sizing: border-box;
}

.matchScoutGridRow {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.matchScoutGridItemHide {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  max-width: 50px;
  max-height: 50px;
  width: 15vw;
  height: 15vw;
  margin: 5px;
  padding: 5px;
  box-sizing: border-box;
}

.matchScoutGridText {
  font-size: 22px;
  text-align: center;
  border-bottom: 1px solid black;
  width: 100%;
}

.matchScoutNoteImg {
  width: 100%;
  height: 100%;
}

.matchScoutAutoScoreList {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.matchScoutAutoScoreItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.matchScoutAutoScoreItems {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.matchScoutingScoreAlign {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  max-width: 150px;
}

.matchScoutModalScreenAlign {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 80%;
  max-width: 600px;
  color: white;
}

.matchScoutingEndgameAlign {
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: center;
  width: 300px;
  margin-bottom: 20px;
}

.matchScoutFieldImg {
  max-width: 800px;
  max-height: 800px;
  width: 80vw;
}

.matchScoutTeleopScoreItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100px;
}

.matchScoutingTeleopAlign {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.matchScoutingButtonTeleop {
  width: 90%;
  max-width: 100px;
}

.matchScoutingTeleopBottomRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.matchScoutingShootingBoxes {
  position: relative;

}

.MatchScoutingPageShotBox {
  position: absolute;
  top: 0;
  left: 0;

}

.MatchScoutingPageShotImage {
  position: absolute;
  pointer-events: none;
}

.matchScoutingFinalNotesAlign {
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: center;
  width: 300px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .matchScoutFieldImg {
    max-width: 550px;
    width: 70vh;
    }
  .matchScoutingScoreAlign {
    padding-right: 100px;
  }

  .matchScoutingButtonTeleop {
    width: 100px;
  }

  .matchScoutingTeleopBottomRow {
    transform: rotate(90deg);
    position: absolute;
    right: 35%;
    width: 600px;
  }
  .matchScoutingShootingBoxes {
    transform: rotate(90deg);
  }
  .MatchScoutingPageShotBox {
  }


}

@media only screen and (max-width: 430px) {
  .matchScoutFieldImg {
    max-width: 550px;
    width: 70vh;
    }
  .matchScoutingScoreAlign {
    padding-right: 100px;
  }

  .matchScoutingButtonTeleop {
    width: 100px;
  }

  .matchScoutingTeleopBottomRow {
    transform: rotate(90deg);
    position: absolute;
    right: 20%;
    width: 600px;
  }
  .matchScoutingShootingBoxes {
    transform: rotate(90deg);
  }
  .MatchScoutingPageShotBox {
  }


}

@media only screen and (max-width: 400px) {
  .matchScoutFieldImg {
    max-width: 550px;
    width: 70vh;
    }
  .matchScoutingScoreAlign {
    padding-right: 100px;
  }

  .matchScoutingButtonTeleop {
    width: 100px;
  }

  .matchScoutingTeleopBottomRow {
    transform: rotate(90deg);
    position: absolute;
    right: 11%;
    width: 600px;
  }
  .matchScoutingShootingBoxes {
    transform: rotate(90deg);
  }
  .MatchScoutingPageShotBox {
  }


}

@media only screen and (max-width: 391px) {
  .matchScoutFieldImg {
    max-width: 550px;
    width: 70vh;
    }
  .matchScoutingScoreAlign {
    padding-right: 100px;
  }

  .matchScoutingButtonTeleop {
    width: 100px;
  }

  .matchScoutingTeleopBottomRow {
    transform: rotate(90deg);
    position: absolute;
    right: 14%;
    width: 600px;
  }
  .matchScoutingShootingBoxes {
    transform: rotate(90deg);
  }
  .MatchScoutingPageShotBox {
  }


}

@media only screen and (max-width: 360px) {
  .matchScoutFieldImg {
    max-width: 550px;
    width: 70vh;
    }
  .matchScoutingScoreAlign {
    padding-right: 100px;
  }

  .matchScoutingButtonTeleop {
    width: 100px;
  }

  .matchScoutingTeleopBottomRow {
    transform: rotate(90deg);
    position: absolute;
    right: 9%;
    width: 600px;
  }
  .matchScoutingShootingBoxes {
    transform: rotate(90deg);
  }
  .MatchScoutingPageShotBox {
  }


}


