.signAllInOutPage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.labelText {
  color: #3D3988;
  font-weight: 500;
  font-size: 24px;
}

.switchBox {
  border: 2px;
  border-color: #7b2185;
  box-shadow: 1px 5px #7b2185;
  border-style: solid;
  border-radius: 30%;
  padding: 20px;
  max-width: 80%;
  width: 275px;
}

.signInButton {
  width: 80%;
  max-width: 300px;
  background-color: green;
}

.alignSessionButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.sessionText {
  padding-bottom: 20px;
  font-size: 24px;
  color: #3D3988;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 90%;
  max-width: 300px;
}

.sessionHourText {
  font-weight: 700;
}

.userSignBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 300px;
  height: 100px;
}
.userSignBoxSignedIn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 300px;
  height: 150px;
}

.allUserSignBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  flex-wrap: wrap;
  overflow-y: scroll;
  margin-top: 20px;
  max-height: 55%;
}

.userNameText {
  padding-bottom: 10px;
}