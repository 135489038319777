.scoutingMainPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.eventSelectDropdown {
    width: 300px;
    max-width: 95%;
    margin-bottom: 20px;
}

.robotListContainer {
    width: 80%;
    height: 60%;
    max-height: 600px;
    overflow-y: scroll;
    max-width: 90%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    justify-content: space-evenly;
}

.robotListContainer::-webkit-scrollbar {
  display: none;
}

.robotList {
    width: 90px;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px;
    border: 2px;
    border-color: gray;
    border-style: solid;
    border-radius: 30%;
}

.robotListGreen {
  width: 90px;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  border: 2px;
  border-color: green;
  border-style: solid;
  border-radius: 30%;
}

.robotListYellow {
  width: 90px;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  border: 2px;
  border-color: #dbb858;
  border-style: solid;
  border-radius: 30%;
}

.robotListRed {
  width: 90px;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  border: 2px;
  border-color: red;
  border-style: solid;
  border-radius: 30%;
}

.robotListName {
    font-size: 11px;
    font-weight: 700;
    color: gray;
    overflow-y: hidden;
}

.robotListNameRed {
  color: red;
  font-size: 11px;
  font-weight: 700;
  overflow-y: hidden;
}


.robotListNameYellow {
  color: #dbb858;
  font-size: 11px;
  font-weight: 700;
  overflow-y: hidden;
}

.robotListNameGreen {
  color: green;
  font-size: 11px;
  font-weight: 700;
  overflow-y: hidden;
}


.robotListNumber {
    font-size: 20px;
    font-weight: 700;
    color: gray;
    overflow-y: hidden;
}

.robotListNumberGreen {
  font-size: 20px;
  font-weight: 700;
  color: green;
  overflow-y: hidden;
}

.robotListNumberYellow {
  font-size: 20px;
  font-weight: 700;
  color: #dbb858;
  overflow-y: hidden;
}

.robotListNumberRed {
  font-size: 20px;
  font-weight: 700;
  color: red;
  overflow-y: hidden;
}

.scoutingMainMatchScoutButton {
    width: 300px;
    max-width: 100%;
    height: 50px;
    margin-bottom: 20px;
    background-color: #dbb858;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 700;
    color: white;
    cursor: pointer;
}

.buttonSpacing {
    margin-bottom: 20px;
}

.modalButtonsAlign {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    max-height: 600px;
    align-items: center;
}

.scoutingPageField {
    width: 100%;
    max-width: 300px;
    display: flex;
}

.modalTextInfo {
    font-size: 20px;
    font-weight: 500;
    color: white;
    text-align: center;
}